(function() {
    this.moment.duration.fn.formatDuration = function() {
        var duration = moment.duration(this);
        if (duration.asHours() === 1 && duration.minutes() === 0) {
            return "{0}hr".format(Math.floor(duration.asHours()));
        } else if (duration.minutes() === 0) {
            return "{0}hrs".format(Math.floor(duration.asHours()));
        } else {
            return "{0}hrs {1}mins".format(Math.floor(duration.asHours()), duration.minutes());
        }
    };

    this.moment.fn.calendarOrFromNow = function() {
        var date = moment(this);
        var days = 10;
        if (moment().subtract(days, 'days') < date && moment().add(days, 'days') > date) {
            return date;
        } else {
            return date.fromNow();
        }
    };

    this.moment.duration.fn.formatLongDuration = function() {
        var duration = moment.duration(this);
        var lengthDescription = null;
        if (duration.years() != 0) {
            lengthDescription = "{0} year".format(duration.years());
            if (duration.years() > 1) {
                lengthDescription += "s";
            }
        } else if (duration.months() != 0) {
            lengthDescription = "{0} month".format(duration.months());
            if (duration.months() > 1) {
                lengthDescription += "s";
            }
        }
        else if (duration.days() != 0) {
            lengthDescription = "{0} day".format(duration.days());
            if (duration.days() > 1) {
                lengthDescription += "s";
            }
        }
        else if (duration.hours() != 0) {
            lengthDescription = "{0} hour".format(duration.hours());
            if (duration.hours() > 1) {
                lengthDescription += "s";
            }
        }
        else if (duration.minutes() != 0) {
            lengthDescription = "{0} minute".format(duration.minutes());
            if (duration.minutes() > 1) {
                lengthDescription += "s";
            }
        }
        else {
            lengthDescription = "a few seconds";
        }

        return lengthDescription;
    };
}).call(this);