ko.observable.fn.withStoreSetting = function(store, key, defaultValue) {
    var exports = this;

    var value = store.store(key);
    if (value) {
        this(value);
    } else {
        this(defaultValue);
    }

    exports.subscribe(function(newValue) {
        if (newValue == undefined) {
            store.store(key, null);
        } else {
            store.store(key, newValue);
        }
    });

    return exports;
};