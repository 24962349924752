(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define(['jquery', 'knockout'], factory);
    } else {
        factory(jQuery, ko);
    }
}(function ($, ko) {
    if (ko !== undefined && ko.bindingHandlers !== undefined) {
        ko.bindingHandlers.highcharts = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                var options = valueAccessor().options;
                var chart = new Highcharts.Chart(options);
            },
            update: function (element, valueAccessor, allBindingsAccessor) {
                var options = valueAccessor().options;
                var chart = new Highcharts.Chart(options);
            }
        };
    }
}));