if (!Date.prototype.yearsAgo) {
    Date.prototype.yearsAgo = function() {
        var today = new Date();
        var yearDifference = today.getFullYear() - this.getFullYear();

        var month = today.getMonth() - this.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < this.getDate())) {
            yearDifference--;
        }

        return yearDifference;
    };
}